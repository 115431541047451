$highlight_background   : #000000;
$highlight_current_line : #2a2a2a;
$highlight_selection    : #424242;
$highlight_selection_bg : #afe7e7;
$highlight_foreground   : #eaeaea;
$highlight_comment      : #969896;
$highlight_red          : #d54e53;
$highlight_orange       : #e78c45;
$highlight_yellow       : #e7c547;
$highlight_green        : #b9ca4a;
$highlight_aqua         : #70c0b1;
$highlight_blue         : #7aa6da;
$highlight_purple       : #c397d8;
$highlight_gutter_color : #666666;
$highlight_gutter_bg    : #292929;
$highlight_addition     : #008000;
$highlight_deletion     : #800000;

@import '../style.scss';