/* 宽度对齐 */

figure.highlight {
    table, table>tbody, table>tbody>tr, table>tbody>tr>td
    {
        display: block;
    }
}

figure.highlight {
    position: relative;
    overflow: auto;
    margin: 20px 0;
    padding: 0;
    border-radius: 8px;
    font-family: Consolas, 'Courier New', monospace, -apple-system;
    font-size: 12px;
    font-weight: unset;
    color: $highlight_foreground;
    background: $highlight_current_line;

    &::before {
        content: " ";
        position: absolute;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fc625d;
        width: 12px;
        height: 12px;
        left: 10px;
        top: 10px;
        -webkit-box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
        box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
    }

    figcaption {
        position: absolute;
        margin-left: 75px;
        font-size: 18px;
    }

    pre {
        border: none;
        margin: 0;
        padding: 10px 0;
        color: $highlight_foreground;
        background: none;
    }

    table {
        margin: 30px 0 0 0;
        width: auto;
        border: none;
        border-spacing: unset;
        border-collapse: unset;
    }

    td {
        border: none;
        padding: 0;
    }

    .gutter {
        position: absolute;
        width: 40px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        pre {
            overflow: hidden;
            padding-left: 10px;
            padding-right: 10px;
            color: $highlight_gutter_color;
            text-align: right;
            background-color: $highlight_gutter_bg;
        }
    }

    .gutter + .code {
        margin-left: 40px;
    }

    .code {
        overflow: auto;
        background-color: $highlight_background;

        pre {
            padding-left: 10px;
            padding-right: 15px;
            text-align: left;
            display: inline-block;

            @mixin selection {
                color: $highlight_selection;
                background: $highlight_selection_bg;
            }
            
            ::-webkit-selection {
                @include selection;
            }
            
            ::-moz-selection {
                @include selection;
            }
            
            ::selection {
                @include selection;
            }
        }
    }

    > button.copy {
        transition: all .3s;
        opacity: 0;
        position: absolute;
        color: $highlight_foreground;
        background: none;
        right: 5px;
        top: 5px;
        font-size: 12px;
        line-height: 12px;
        padding: 3px 5px;
        border: 1px solid;
        border-radius: 4px;
    }

    &:hover > button.copy {
        opacity: 1;
    
        &:hover {
            color: $highlight_aqua;
        }
    }
}

.gist table {
    width: auto;

    td {
        border: none;
    }
}

pre {
    .addition {
        background: $highlight_addition;
    }

    .deletion {
        background: $highlight_deletion;
    }

    .meta {
        color: $highlight_purple;
    }

    .comment {
        color: $highlight_comment;
    }

    .variable,
    .attribute,
    .tag,
    .regexp,
    .ruby .constant,
    .xml .tag .title,
    .xml .pi,
    .xml .doctype,
    .html .doctype,
    .css .id,
    .css .class,
    .css .pseudo {
        color: $highlight_red;
    }

    .number,
    .preprocessor,
    .built_in,
    .literal,
    .params,
    .constant,
    .command {
        color: $highlight_orange;
    }

    .ruby .class .title,
    .css .rules .attribute,
    .string,
    .value,
    .inheritance,
    .header,
    .ruby .symbol,
    .xml .cdata,
    .special,
    .number,
    .formula {
        color: $highlight_green;
    }

    .title,
    .css .hexcolor {
        color: $highlight_aqua;
    }

    .function,
    .python .decorator,
    .python .title,
    .ruby .function .title,
    .ruby .title .keyword,
    .perl .sub,
    .javascript .title,
    .coffeescript .title {
        color: $highlight_blue;
    }

    .keyword,
    .javascript .function {
        color: $highlight_purple;
    }
}
